.get-started {
  display: inline-block; /* Default: visible */
}

header {
  background-color: white; /* Set background to white */
  color: black; /* Set text color to black */
}


@media screen and (max-width: 768px) {
  .whatsapp-button {
    padding: '0.3rem 0.75rem' !important;
    font-size: '0.9rem' !important;
  }
  
  .whatsapp-icon {
    width: 16px !important;
    height: 16px !important;
  }
}