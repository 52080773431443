/* General styles */
.hero-container {
  position: relative;
  text-align: center;
  color: rgb(21, 20, 20);
  width: 100%;
  margin: 0 auto;
  padding: 0;
  /* background-color:rgb(26, 26, 46);; */
  height: 30vh; /* Full-screen height */
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 90%;
}

.hero-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero-content h2 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.hero-button {
  display: inline-block;
  background-color: #c6a45c;
  color: #fff;
  padding: 0.75rem 2rem;
  border-radius: 9999px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.hero-button:hover {
  background-color: #a38648;
  transform: scale(1.05);
}

/* Responsive Design */

/* For screens smaller than 1024px */
@media (max-width: 1024px) {
  .hero-content h1 {
    font-size: 2.5rem;
  }
  .hero-content h2 {
    font-size: 1.25rem;
  }
}

/* For screens smaller than 768px */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2rem;
  }
  .hero-content h2 {
    font-size: 1.125rem;
  }
}

/* For screens smaller than 480px */
@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 1.5rem;
  }
  .hero-content h2 {
    font-size: 1rem;
  }
  .hero-content {
    width: 95%;
  }
}
